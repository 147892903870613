import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import SEO from "../components/seo"
import Layout from "../components/layout"
import { navigate } from "gatsby"
import { accessrequest } from "../actions/auth"
import { TheCountries } from "../components/profile/CountryConst"
import { useDispatch, useSelector } from "react-redux"
import { refreshAuthTrue } from "../actions"

export const Access = () => {
  const [form, setForm] = useState({
    lastname: "",
    firstname: "",
    email: "",
    password: "",
    id_gender: "1",
    societe: "",
    ntva: "",
    siren: "",
    telephone_fixe: "",
    pays: 8,
    ville: "",
    codePostal: "",
    adresse: "",
    countryname: "France",
    msg: "",
  })
  const dispatch = useDispatch()

  const identifiants = useSelector(s => s.identifiants)
  const loading = useSelector(s => s.loading)
  useEffect(() => dispatch(refreshAuthTrue()), [])

  const handleUpdate = event => {
    if (event.target.name === "firstname" || event.target.name === "lastname" || event.target.name === "company") {
      setForm({ ...form, [event.target.name]: event.target.value.replace(/[^A-Za-zÀ-ÖØ-öø-ÿ ]/g, "") })
    } else if (event.target.name === "country") {
      setForm({ ...form, pays: event.target.value, countryname: TheCountries.find(elem => elem.id_country == event.target.value).name })
    } else {
      setForm({ ...form, [event.target.name]: event.target.value })
    }
  }

  const handleSubmit = event => {
    event.preventDefault()
    dispatch(accessrequest(form))
  }

  if (identifiants.identifiants && identifiants.identifiants.firstname && identifiants.identifiants.lastname) {
    if (typeof window !== "undefined") {
      navigate(`/`)
    }
  }
  return (
    <>
      <Layout banner={false} location={{ pathname: "/" }}>
        <SEO title="Demande d'accès" description="Demandez l'accès aux les professionnels du cadre pour commander" />
        <div className="columns is-centered login" style={{ marginTop: "6rem" }}>
          <div className="section column is-half couleurShadow">
            <h2 className="dotted">Demandez l&apos;accès au site</h2>
            <h3 className="">
              En cas de question, nous sommes à votre disposition au <b>03 89 06 00 93</b>
            </h3>
            <form method="post" onSubmit={event => handleSubmit(event)}>
              <div className="field">
                <label className="label" htmlFor="firstname">
                  Prénom&nbsp;:
                </label>
                <div className="control">
                  <input type="text" id="firstname" name="firstname" onChange={handleUpdate} required className="input" placeholder="Votre prénom" />
                </div>
              </div>

              <div className="field">
                <label className="label" htmlFor="lastname">
                  Nom&nbsp;:
                </label>
                <div className="control">
                  <input type="text" id="lastname" name="lastname" onChange={handleUpdate} required className="input" placeholder="Votre nom" />
                </div>
              </div>

              <div className="field">
                <label className="label" htmlFor="company">
                  Nom entreprise&nbsp;:
                </label>
                <div className="control">
                  <input type="text" id="company" name="societe" onChange={handleUpdate} required className="input" placeholder="Nom de votre entreprise" />
                </div>
              </div>

              <div className="field">
                <label className="label" htmlFor="TVA">
                  N° TVA&nbsp;:
                </label>
                <div className="control">
                  <input type="text" id="TVA" name="ntva" onChange={handleUpdate} className="input" placeholder="N° TVA de votre entreprise" />
                </div>
              </div>

              <div className="field">
                <label className="label" htmlFor="siren">
                  N° Siren&nbsp;:
                </label>
                <div className="control">
                  <input type="text" id="siren" name="siren" onChange={handleUpdate} required className="input" placeholder="N° Siren de votre entreprise" />
                </div>
              </div>

              <div className="field">
                <label className="label" htmlFor="address">
                  Adresse&nbsp;:
                </label>
                <div className="control">
                  <input type="text" id="address" name="adresse" onChange={handleUpdate} required className="input" placeholder="Adresse postale" />
                </div>
              </div>

              <div className="field">
                <label className="label" htmlFor="cp">
                  Code postal et ville&nbsp;:
                </label>
                <div className="field-body">
                  <div className="field">
                    <div className="control">
                      <input type="text" id="cp" name="codePostal" onChange={handleUpdate} required className="input" placeholder="Code postal" />
                    </div>
                  </div>
                  <div className="field">
                    <div className="control">
                      <input type="text" id="ville" name="ville" onChange={handleUpdate} required className="input" placeholder="Ville" />
                    </div>
                  </div>
                </div>
              </div>

              <div className="field">
                <label className="label" htmlFor="phone">
                  Tél&nbsp;:
                </label>
                <div className="control">
                  <input
                    type="tel"
                    id="phone"
                    name="telephone_fixe"
                    onChange={handleUpdate}
                    required
                    className="input"
                    placeholder="Votre numéro de téléphone"
                  />
                </div>
              </div>

              <div className="field">
                <label className="label" htmlFor="country">
                  Pays&nbsp;:
                </label>
                <div className="control select">
                  <select name="pays" id="country" onBlur={handleUpdate}>
                    {TheCountries.map((node, index) => (
                      <option key={index} value={node.id_country} selected={form.pays == node.id_country}>
                        {node.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="field">
                <label className="label" htmlFor="email">
                  Adresse email&nbsp;:
                </label>
                <div className="control">
                  <input type="email" id="email" name="email" onChange={handleUpdate} required className="input" placeholder="Votre adresse email" />
                </div>
                {loading.isRefresh ? (
                  ""
                ) : (
                  <div className="notification is-danger" style={{ marginTop: "1rem" }}>
                    L&apos;adresse email est déjà utilisée, veuillez en choisir une autre ou vous connecter
                  </div>
                )}
              </div>

              <div className="field">
                <label className="label" htmlFor="msg">
                  Message
                </label>
                <div className="control">
                  <textarea name="other" id="msg" className="textarea" rows="3" onChange={handleUpdate}></textarea>
                </div>
              </div>

              <div className="field">
                <label className="label" htmlFor="number">
                  Donner le nombre de lettres du mots &quot;CADRE&quot;
                </label>
                <i>exemple, pour &quot;FRANCE&quot; c&apos;est : 6</i>
                <div className="control">
                  <input className="input" name="number" id="number" type="number" required onChange={handleUpdate} style={{ width: "20%" }} />
                </div>
              </div>

              <div className="field is-grouped" style={{ marginTop: "2rem" }}>
                <div className="control">
                  <input type="submit" value="Envoyer" />
                </div>
              </div>
            </form>
          </div>
        </div>
      </Layout>
    </>
  )
}

Access.propTypes = {
  location: PropTypes.object,
}

export default Access
